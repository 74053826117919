import * as React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { helpers, styled } from 'react-free-style';
import { parse } from 'querystring';

import { countNewDataRequests } from '@united-talent-agency/julius-frontend-store';

import * as elements from '../../styles/elements';
import PeopleView from './dashboard/views/people/people';
import CompaniesView from './dashboard/views/companies/companies';
import RequestsView from './dashboard/views/requests/requests';
import NewPeopleView from './dashboard/views/new-people/new-people';
import ReportsView from './dashboard/views/reports/reports';
import MergePeople from './dashboard/views/merge-people/merge-people';
import MergeCompanies from './dashboard/views/merge-companies/merge-companies';
import ChangedPeople from './dashboard/views/changed-people/changed-people';
import DuplicatePeople from './dashboard/views/duplicate-people/duplicate-people';
import UploadContacts from './dashboard/views/upload-contacts/upload-contacts';

import leftMenuBackground from '../../assets/images/uta-background.jpg';

import { datadogRum } from '@datadog/browser-rum';

// Flag used to register what User is active in the application for DataDog.
let DATA_DOG_SET = false;

const { REACT_APP_FEATURE_VENUES } = process.env;

const views = [
  { view: 'people', title: 'People', icon: 'fa-users' },
  { view: 'companies', title: 'Companies', icon: 'fa-building' },
  { view: 'merge-people', title: 'Merge - Ppl', icon: 'fa-compress' },
  { view: 'merge-companies', title: 'Merge - Comp.', icon: 'fa-compress' },
  { view: 'changed-people', title: 'Changes', icon: 'fa-exchange' },
  { view: 'duplicate-people', title: 'Duplicates', icon: 'fa-clone' },
  { view: 'upload-contacts', title: 'Bulk Upload', icon: 'fa-rotate-90 fa-sign-in' },
  { view: 'data-requests', title: 'Requests', icon: 'fa-magic', showDataRequestIndicator: true },
  { view: 'new-people', title: 'New People', icon: 'fa-users' },
  { view: 'reports', title: 'Reports', icon: 'fa-file' },
];

if (REACT_APP_FEATURE_VENUES === true.toString()) {
  views.splice(2, 0, { view: 'venues', title: 'Venues', icon: 'fa-grav' });
}

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 'people',
    };
  }

  componentDidMount() {
    this.props.dispatch(countNewDataRequests());

    this.setViewFromLocation();

    // if no configuration is active, DD should be set to true
    if (!datadogRum.getInitConfiguration()) {
      DATA_DOG_SET = true;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.setViewFromLocation();
    }

    const { user } = this.props;

    // Flag allows this to fire 1x per user-login, as desired.
    if (user && user.azure_id && !DATA_DOG_SET) {
      datadogRum.setUser({
        id: user.azure_id,
        name: `${user.last_name}, ${user.first_name}`,
        email: user.email,
      });
      datadogRum.startSessionReplayRecording();
      DATA_DOG_SET = true;
    }
  }

  setViewFromLocation() {
    const qs = parse(this.props.location.search.substr(1));
    const view = qs.view || 'people';

    this.setState({ view });
  }

  renderMenuLinks() {
    const { newDataRequests } = this.props.dataRequest;
    const { view } = this.state;

    return views.map((_view, index) => {
      const buttonColor = (view === _view.view && 'btn-light') || 'btn-dark';

      return (
        <Link key={index} to={{ search: `?view=${encodeURIComponent(_view.view)}` }}>
          <div className={`m-1`}>
            <Button className={`text-left btn-block ${buttonColor}`}>
              <i className={`mr-2 fa ${_view.icon}`} aria-hidden="true" />
              {_view.title}

              {_view.showDataRequestIndicator && newDataRequests > 0 && (
                <span className={`ml-2 badge badge-warning`}>{newDataRequests}</span>
              )}
            </Button>
          </div>
        </Link>
      );
    });
  }

  render() {
    const { styles } = this.props;
    const { view } = this.state;

    return (
      <React.Fragment>
        <div className="m-0 p-0">
          <div className={styles.wrapper}>
            <div className={styles.menu}>{this.renderMenuLinks()}</div>
            <div className={styles.content}>
              {view === 'people' && <PeopleView />}
              {view === 'companies' && <CompaniesView category='Company' />}
              {view === 'venues' && <CompaniesView category='Venue' />}
              {view === 'merge-people' && <MergePeople />}
              {view === 'merge-companies' && <MergeCompanies />}
              {view === 'duplicate-people' && <DuplicatePeople />}
              {view === 'changed-people' && <ChangedPeople />}
              {view === 'upload-contacts' && <UploadContacts />}
              {view === 'data-requests' && <RequestsView />}
              {view === 'new-people' && <NewPeopleView />}
              {view === 'reports' && <ReportsView />}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const withStyles = styled({
  wrapper: {},
  content: {
    float: 'left',
    width: '85%',
    fontSize: '10px',
    fontWeight: '100',
    paddingLeft: 20,
    paddingRight: 20,
  },
  menu: {
    width: '15%',
    float: 'left',
    margin: 0,
    padding: 0,
    height: '100vh',
    backgroundImage: `url(${leftMenuBackground})`,
  },
  menuItem: helpers.merge(elements.menuItem, {
    width: '80%',
    height: '60px',
  }),
  activeItem: {
    color: '#fff',
    background: '#000',
    float: 'left',
  },
});

const withState = connect((store, ownProps) => {
  const personId = ownProps.computedMatch.params.personId;

  const { user, dataRequest } = store;
  const { person } = store.profile;

  return { personId, person, dataRequest, user };
});

export default withStyles(withState(Dashboard));
