import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { helpers, styled } from 'react-free-style';
import { Table } from 'reactstrap';
import download from 'downloadjs';

import { elements, icons } from '@united-talent-agency/julius-frontend-components';
import { requestPromise, loadDataRequests, updateDataRequest } from '@united-talent-agency/julius-frontend-store';

import buildProjectQuery from '../../../../shared/projects/build-projects-query';
import { apiServerUrl } from '../../../../../support/urls';

const ALL_REQUEST_STATUSES = ['New', 'In Progress', 'Done'];

class Requests extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      type: '',
      personName: '',
      personTitle: '',
      personCompany: '',
      personType: '',
      modalIsOpen: false,
      status: 'All',
      isPrintingClientList: false,
    };
    this.refreshRequests();
  }

  async refreshRequests(offset = 0, statuses = ALL_REQUEST_STATUSES) {
    const { dispatch, dataRequest } = this.props;
    const query = [
      { $skip: offset },
      { $limit: dataRequest.limit },
      ...statuses.map(status => {
        return { status };
      }),
      { '$sort[status]': -1 },
    ];
    dispatch(loadDataRequests(query));
  }

  updateRequest(id, payload) {
    const { dispatch } = this.props;
    dispatch(updateDataRequest(id, payload));
  }

  handlePageClick (data) {
  let selected = data.selected;
    let offset = Math.ceil(selected * this.props.dataRequest.limit);
    this.setState({ skip: offset });
    const statuses = this.state.status === 'All' ? ALL_REQUEST_STATUSES : [this.state.status];
    this.refreshRequests(offset, statuses);
  }

  render() {
    const { styles } = this.props;
    const { isPrintingClientList } = this.state;

    return (
      <div className={styles.container}>
        <h1>Reports</h1>

        <Table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.thStatus}>Report</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div
                  className={styles.filterLabel}
                  onClick={() => {
                    this.downloadPdf('project_report_client');
                  }}
                >
                  {isPrintingClientList ? (
                    <i className="fa fa-fw fa-circle-o-notch fa-spin" aria-hidden="true" />
                  ) : (
                    <i className="fa fa-fw fa-print" aria-hidden="true" />
                  )}
                  Print Client List
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }

  async requestFile(reportName, reportQuery, contentType, fileName) {
    const fileRequest = await requestPromise(
      this.props.user,
      apiServerUrl,
      `/${reportName}`,
      'GET',
      null,
      reportQuery,
      contentType
    );
    const responseBlob = await fileRequest.blob();
    download(responseBlob, fileName, contentType);
  }

  async downloadFile(reportName, fileType) {
    this.setIsDownloading(reportName, fileType, true);

    const { filters } = this.props;
    const currentFilter = filters.filters[filters.currentFilterIndex];
    const filterValues = currentFilter ? currentFilter.values : {};
    const projectQuery = buildProjectQuery(filterValues);

    let contentType, fileName;
    if (fileType === 'pdf') {
      contentType = 'application/pdf';
      fileName = `${reportName}.pdf`;
    }
    await this.requestFile(reportName, projectQuery, contentType, fileName);

    this.setIsDownloading(reportName, fileType, false);
  }

  downloadPdf(reportName) {
    if (!this.state.isPdfFileDownloading) {
      this.downloadFile(reportName, 'pdf');
    }
  }

  getStatusList(styles) {
    const allStatus = {
      key: 'All',
      content: 'All',
      active: 'All' === this.state.status,
      className: styles.menuItem,
      onClick: () => {
        this.refreshRequests(0, ALL_REQUEST_STATUSES);
        this.setState({ status: 'All' });
      },
    };

    const individualStatuses = ALL_REQUEST_STATUSES.map(status => ({
      key: status,
      content: status,
      active: status === this.state.status,
      className: styles.menuItem,
      onClick: () => {
        this.refreshRequests(0, [status]);
        this.setState({ status });
      },
    }));

    return [allStatus, ...individualStatuses];
  }

  setIsDownloading(reportName, fileType, isDownloading) {
    if (reportName === 'project_report_client' && fileType === 'pdf') {
      this.setState({ isPrintingClientList: isDownloading });
    }
  }
}

const TABLE_WIDTH = 600;

const withStyles = styled({
  container: {
    width: TABLE_WIDTH,
  },
  filterBar: {
    width: TABLE_WIDTH,
    height: '70px',
  },
  filterLabel: {
    float: 'left',
    fontSize: 16,
    fontWight: 1000,
    textAlign: 'center',
    marginRight: '20px',
    marginTop: '10px',
  },
  pagination: {
    display: 'inline-block',
    float: 'right',
    paddingLeft: 0,
    marginLeft: 0,
    color: 'black',
    '& > li > a': {
      color: 'black !important',
    },
    '& > li': {
      display: 'inline-block',
      color: 'black !important',
      margin: 5,
      padding: 5,
    },
    '& > li.selected': {
      background: 'black',
    },
    '& > li.selected > a': {
      color: 'white !important',
    },
  },
  table: {
    background: '#fff',
    verticalAlign: 'middle',
  },
  filterField: {
    width: '150px',
    marginRight: 10,
    float: 'left',
  },
  header: {
    color: '#000',
    textAlign: 'center',
    fontSize: '13px',
    lineHeight: '1',
    background: 'white',
    marginBottom: 5,
  },
  newButton: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderColor: '#000',
    float: 'right',
  }),
  newButtonIcon: helpers.merge(
    {
      marginRight: 10,
    },
    icons.plus
  ),
  menuItem: {
    zIndex: 1000000,
  },
  clearFilters: {
    float: 'left',
    fontSize: 8,
    color: 'black',
    textDecoration: 'underline',
    textAlign: 'center',
    marginRight: '20px',
    marginTop: '15px',
    cursor: 'pointer',
  },
  thStatus: {
    width: 100,
  },
  thActions: {
    width: 287,
  },
});

const withState = connect(state => {
  const { filters, dataRequest } = state;
  const { createdId } = state.dashboard;
  const { user } = state.user;
  return { createdId, dataRequest, filters, user };
});

export default withRouter(withState(withStyles(Requests)));
