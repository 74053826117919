import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { helpers, styled } from 'react-free-style';
import ReactPaginate from 'react-paginate';
import { debounce } from 'lodash';

import {
  Select,
  Table,
  CountColumn,
  ObjectColumn,
  ButtonColumn,
  Input,
  elements,
  icons,
} from '@united-talent-agency/julius-frontend-components';
import { deletePerson, listPeople, personTypes } from '@united-talent-agency/julius-frontend-store';
import { openNewPersonProfile, openPersonProfile, selectPeopleWithPrimaryGroup } from '../../../../../support/person';
import { ConfirmModal } from '@united-talent-agency/components';

const SELECTABLE_TYPES = [personTypes.client, personTypes.industryContact, personTypes.shared, personTypes.employee];

const TABLE_COLUMNS = [
  {
    key: 'name',
    name: 'Name',
    sortable: false,
    width: 200,
  },
  {
    key: 'type',
    name: 'Type',
    width: 110,
    sortable: false,
  },
  {
    key: 'primaryGroup',
    name: 'Company',
    width: 200,
    sortable: false,
    formatter: ObjectColumn,
  },
  {
    key: 'profile_pic',
    name: 'Picture',
    width: 250,
    sortable: false,
  },
  {
    key: 'contacts',
    name: 'Contacts',
    width: 60,
    sortable: false,
    formatter: CountColumn,
  },
  {
    key: 'addresses',
    name: 'Addresses',
    width: 60,
    sortable: false,
    formatter: CountColumn,
  },
  {
    key: '_id',
    name: '',
    width: 100,
    sortable: false,
  },
];

class People extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      type: '',
      showDeleteConfirmForId: null,
    };
    this.peopleSearchDebounced = debounce(this.peopleSearch, 400);
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  componentDidMount() {
    this.refreshPeople();
  }

  peopleSearch(query) {
    const { dispatch } = this.props;
    dispatch(listPeople(query));
  }

  createPersonClicked(event) {
    openNewPersonProfile();
    event.target.blur();
  }

  onTextChange (field, text) {
    this.setState({ [field]: text });
  }

  searchPerson(name, type, isClear) {
    const query = this.constructQuery(name, type, isClear);
    this.peopleSearchDebounced(query);
  }

  refreshPeople(name, type, isClear) {
    const query = this.constructQuery(name, type, isClear);
    this.peopleSearch(query);
  }

  constructQuery(name, type, isClear) {
    const { peopleFilters } = this.props;
    name && this.setState({ name: name });
    type && this.setState({ type: type });
    let search = '';
    let types = [];
    if (!isClear) {
      if (name || this.state.name) {
        search += `name:${name || this.state.name}`;
      }
      if (type || this.state.type) {
        types.push({ type: type || this.state.type });
      } else {
        types.push(
          { type: personTypes.shared },
          { type: personTypes.client },
          { type: personTypes.industryContact },
          { type: personTypes.employee }
        );
      }
    } else {
      types.push(
        { type: personTypes.shared },
        { type: personTypes.client },
        { type: personTypes.industryContact },
        { type: personTypes.employee }
      );
    }
    return [
      { $populate: 'groupMembership' },
      { $populate: 'groupMembership.group' },
      { $skip: peopleFilters.offset },
      { $limit: peopleFilters.itemsPerPage },
      { $filter: search },
      { $excludes: 'profile_pic' },
      ...types,
    ];
  }

  handlePageClick ({ selected }) {
    const { peopleFilters = {} } = this.props;
    let offset = Math.ceil(selected * peopleFilters.itemsPerPage);
    peopleFilters.offset = offset;
    this.refreshPeople();
  }

  render() {
    const { styles, people, peopleFilters } = this.props;
    const { showDeleteConfirmForId } = this.state;

    const types = SELECTABLE_TYPES.map(type => ({
      key: type,
      content: type,
      active: type === this.state.type,
      className: styles.menuItem,
      onClick: () => this.refreshPeople(null, type),
    }));

    TABLE_COLUMNS[TABLE_COLUMNS.length - 1].formatter = (
      <ButtonColumn buttonText="Delete" onClick={id => this.setState({ showDeleteConfirmForId: id })} />
    );

    return (
      <div>
        <ConfirmModal
          message="Are you sure you want to permanently delete this item?"
          title="Delete Confirmation"
          isOpen={!!showDeleteConfirmForId}
          onConfirm={() => {
            this.props.dispatch(deletePerson(showDeleteConfirmForId)).then(() => this.refreshPeople());
            this.setState({ showDeleteConfirmForId: null });
          }}
          onCancel={() => this.setState({ showDeleteConfirmForId: null })}
        />
        <h1>Manage - People</h1>

        <div className={styles.filterBar}>
          <span className={styles.filterLabel}>Filters:</span>
          <Input
            title="Name"
            className={styles.filterField}
            value={this.state.name}
            onChange={e => this.searchPerson(e, null)}
          />
          <Select className={styles.filterField} items={types}>
            {this.state.type}
          </Select>
          <span
            className={styles.clearFilters}
            onClick={() => {
              this.setState({ name: '', type: '' }, () => {
                this.refreshPeople(null, null, true);
              });
            }}
          >
            Clear Filters
          </span>
          <button onClick={this.createPersonClicked} className={styles.newButton}>
            <i className={styles.newButtonIcon} />
            Create Person
          </button>
        </div>

        <div className={styles.table}>
          <Table
            rowSelection={this.props.rowSelection}
            onSelect={cell => {
              // this is to disable opening the edit when button column is selected
              if (cell.idx < TABLE_COLUMNS.length - 1) {
                openPersonProfile(people[cell.rowIdx]);
              }
            }}
            selectable={this.props.selectRow}
            columns={TABLE_COLUMNS}
            rows={people}
          />
          <ReactPaginate
            previousLabel="previous"
            nextLabel="next"
            breakLabel="..."
            breakClassName={styles.breakPagination}
            pageCount={Math.ceil(this.props.peopleCount / peopleFilters.itemsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName={styles.pagination}
            subContainerClassName={styles.pages}
            activeClassName={styles.activePagination}
          />
        </div>
      </div>
    );
  }
}

const TABLE_WIDTH = '1000px';

const withStyles = styled({
  content: {
    fontSize: '12px',
    fontWeight: '100',
  },
  filterBar: {
    width: TABLE_WIDTH,
    height: '70px',
  },
  filterLabel: {
    float: 'left',
    fontSize: 16,
    fontWight: 1000,
    textAlign: 'center',
    marginRight: '20px',
    marginTop: '10px',
  },
  pagination: {
    display: 'inline-block',
    float: 'right',
    paddingLeft: 0,
    marginLeft: 0,
    color: 'black',
    '& > li > a': {
      color: 'black !important',
    },
    '& > li': {
      display: 'inline-block',
      color: 'black !important',
      margin: 5,
      padding: 5,
    },
    '& > li.selected': {
      background: 'black',
    },
    '& > li.selected > a': {
      color: 'white !important',
    },
  },
  table: {
    width: TABLE_WIDTH,
  },
  filterField: {
    width: '150px',
    marginRight: 10,
    float: 'left',
  },
  header: {
    color: '#000',
    textAlign: 'center',
    fontSize: '13px',
    lineHeight: '1',
    background: 'white',
    marginBottom: 5,
  },
  newButton: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderColor: '#000',
    float: 'right',
  }),
  newButtonIcon: helpers.merge(
    {
      marginRight: 10,
    },
    icons.plus
  ),
  menuItem: {
    zIndex: 1000000,
  },
  clearFilters: {
    float: 'left',
    fontSize: 8,
    color: 'black',
    textDecoration: 'underline',
    textAlign: 'center',
    marginRight: '20px',
    marginTop: '15px',
    cursor: 'pointer',
  },
});

const withState = connect(state => {
  const { people, peopleCount, peopleFilters, createdId } = state.dashboard;
  return {
    people: selectPeopleWithPrimaryGroup(people),
    peopleCount,
    peopleFilters,
    createdId,
  };
});

export default withRouter(withState(withStyles(People)));
