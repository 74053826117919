import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { helpers, styled } from 'react-free-style';
import { isEmpty, debounce } from 'lodash';

import {
  Select,
  Table,
  CountColumn,
  ObjectColumn,
  ButtonColumn,
  Input,
  MergeTable,
  elements,
  icons,
} from '@united-talent-agency/julius-frontend-components';
import { listPeople, savePerson, mergePerson, personTypes } from '@united-talent-agency/julius-frontend-store';
import ReactPaginate from 'react-paginate';

import MergeForm from '../merge-form/merge-form';
import { openPersonProfile, selectPeopleWithPrimaryGroup } from '../../../../../support/person';

const selectableTypes = [personTypes.client, personTypes.shared, personTypes.industryContact];

class People extends React.Component {
  constructor(props) {
    super(props);
    const columns = [
      {
        key: 'name',
        name: 'Name',
        sortable: false,
        width: 200,
      },
      {
        key: 'type',
        name: 'Type',
        width: 110,
        sortable: false,
      },
      {
        key: 'primaryGroup',
        name: 'Company',
        width: 200,
        sortable: false,
        formatter: ObjectColumn,
      },
      {
        key: 'profile_pic',
        name: 'Picture',
        width: 250,
        sortable: false,
      },
      {
        key: 'contacts',
        name: 'Contacts',
        width: 60,
        sortable: false,
        formatter: CountColumn,
      },
      {
        key: 'addresses',
        name: 'Addresses',
        width: 60,
        sortable: false,
        formatter: CountColumn,
      },
      {
        key: '_id',
        name: '',
        width: 100,
        sortable: false,
        formatter: (
          <ButtonColumn
            buttonText="Merge..."
            onClick={id => {
              this.setState({ selectedPeople: { ...this.state.selectedPeople, [id]: this.props.personDict[id] } });
            }}
          />
        ),
      },
    ];

    this.state = {
      name: '',
      type: '',
      personName: '',
      personTitle: '',
      personCompany: '',
      personType: '',
      columns,
      modalIsOpen: false,
      selectedPeople: {},
      merging: false,
    };

    this.peopleSearchDebounced = debounce(this.refreshPeople, 400);
    this.removeFromMerge = this.removeFromMerge.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.savePerson = this.savePerson.bind(this);
    this.startMerge = this.startMerge.bind(this);
    this.dispatchMerge = this.dispatchMerge.bind(this);
    this.cancelMerge = this.cancelMerge.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  componentDidMount() {
    this.refreshPeople();
  }

  removeKey (obj, key) {
    return Object.keys(obj)
      .filter(k => k !== key)
      .reduce((newSelected, key) => {
        newSelected[key] = obj[key];
        return newSelected;
      }, {});
  }

  removeFromMerge (id) {
    this.setState({
      selectedPeople: this.removeKey(this.state.selectedPeople, id),
      selectedValues: {},
      selectedCells: {},
    });
  }

  openModal () {
    this.setState({ modalIsOpen: true, personName: '', personType: '' });
  }

  closeModal () {
    this.setState({ modalIsOpen: false, personName: '', personType: '' });
  }

  onTextChange (field, text) {
    this.setState({ [field]: text });
  }

  savePerson () {
    const { dispatch } = this.props;
    const person = {
      name: this.state.personName,
      title: this.state.personTitle,
      company: this.state.personCompany,
      type: this.state.personType,
    };
    dispatch(savePerson(person)).then(() => {
      this.setState({ modalIsOpen: false });
    });
  }

  refreshPeople(name, type, isClear) {
    const { dispatch, peopleFilters } = this.props;
    if (name) {
      this.setState({ name: name });
    }
    if (type) {
      this.setState({ type: type });
    }
    let search = '';
    let types = [];
    if (!isClear) {
      if (name || this.state.name) {
        search += `name:${name || this.state.name}`;
      }
      if (type || this.state.type) {
        types.push({ type: type || this.state.type });
      } else {
        types.push({ type: personTypes.shared }, { type: personTypes.client }, { type: personTypes.industryContact });
      }
    } else {
      types.push({ type: personTypes.shared }, { type: personTypes.client }, { type: personTypes.industryContact });
    }
    const query = [
      { $populate: 'groupMembership' },
      { $populate: 'groupMembership.group' },
      { $skip: peopleFilters.offset },
      { $limit: peopleFilters.itemsPerPage },
      { $filter: search },
      ...types,
    ];

    dispatch(listPeople(query));
  }

  startMerge () {
    this.setState({ merging: true });
  }

  dispatchMerge (values) {
    const { selectedPeople } = this.state;
    const ids = Object.keys(selectedPeople);
    const { dispatch } = this.props;
    const actionBody = { values, ids };
    dispatch(mergePerson(actionBody));
    this.resetTableAndFilters();
  }

  cancelMerge () {
    this.resetTableAndFilters();
  }

  resetTableAndFilters() {
    this.clearFilters();
    this.setState({ selectedPeople: {}, selectedValues: {}, selectedCells: {}, merging: false });
  }

  clearFilters () {
    this.setState({ name: '', type: '' }, () => {
      this.refreshPeople(null, null, true);
    });
  }

  handlePageClick (data) {
    let selected = data.selected;
    let offset = Math.ceil(selected * this.props.peopleFilters.itemsPerPage);
    this.props.peopleFilters.offset = offset;
    this.refreshPeople();
  }

  renderTables() {
    const { styles, peopleFilters } = this.props;
    const { columns, selectedPeople, selectedCells } = this.state;
    let { people } = this.props;
    people =
      people &&
      people.filter(person => {
        return !(person._id in this.state.selectedPeople);
      });

    const types = selectableTypes.map(type => ({
      key: type,
      content: type,
      active: type === this.state.type,
      className: styles.menuItem,
      onClick: () => this.refreshPeople(null, type),
    }));

    return (
      <div>
        <h1>Merge - People</h1>
        <div className={styles.filterBar}>
          <span className={styles.filterLabel}>Filters:</span>
          <Input
            title="Name"
            className={styles.filterField}
            value={this.state.name}
            onChange={e => this.peopleSearchDebounced(e, null)}
          />
          <Select className={styles.filterField} items={types}>
            {this.state.type}
          </Select>
          <span className={styles.clearFilters} onClick={this.clearFilters}>
            Clear Filters
          </span>
        </div>
        <div className={`${styles.table}`}>
          <Table
            rowSelection={this.props.rowSelection}
            onSelect={cell => {
              if (cell.idx < columns.length - 1) {
                openPersonProfile(people[cell.rowIdx]);
              }
            }}
            selectable={this.props.selectRow}
            columns={this.state.columns}
            rows={people}
          />
          <div>
            <ReactPaginate
              previousLabel="previous"
              nextLabel="next"
              breakLabel="..."
              breakClassName={styles.breakPagination}
              pageCount={Math.ceil(this.props.peopleCount / peopleFilters.itemsPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={styles.pagination}
              subContainerClassName={styles.pages}
              activeClassName={styles.activePagination}
            />
          </div>
          {!isEmpty(this.state.selectedPeople) && (
            <div>
              <button onClick={this.startMerge} className={styles.newButton}>
                <i className={styles.newButtonIcon} />
                Merge Selected...
              </button>
              <MergeTable
                removeFromMerge={this.removeFromMerge}
                selected={selectedPeople}
                selectedCells={selectedCells}
                editAction={openPersonProfile}
                minHeight={300}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  renderMergeForm() {
    const { selectedPeople } = this.state;
    return (
      <MergeForm dispatchMerge={this.dispatchMerge} cancelMerge={this.cancelMerge} selectedPeople={selectedPeople} />
    );
  }

  render() {
    return this.state.merging ? this.renderMergeForm() : this.renderTables();
  }
}

const TABLE_WIDTH = '1000px';

const withStyles = styled({
  content: {
    fontSize: '12px',
    fontWeight: '100',
  },
  filterBar: {
    width: TABLE_WIDTH,
    height: '70px',
  },
  filterLabel: {
    float: 'left',
    fontSize: 16,
    fontWight: 1000,
    textAlign: 'center',
    marginRight: '20px',
    marginTop: '10px',
  },
  pagination: {
    display: 'inline-block',
    float: 'right',
    paddingLeft: 0,
    marginLeft: 0,
    color: 'black',
    '& > li > a': {
      color: 'black !important',
    },
    '& > li': {
      display: 'inline-block',
      color: 'black !important',
      margin: 5,
      padding: 5,
    },
    '& > li.selected': {
      background: 'black',
    },
    '& > li.selected > a': {
      color: 'white !important',
    },
  },
  table: {
    width: TABLE_WIDTH,
  },
  filterField: {
    width: '150px',
    marginRight: 10,
    float: 'left',
  },
  header: {
    color: '#000',
    textAlign: 'center',
    fontSize: '13px',
    lineHeight: '1',
    background: 'white',
    marginBottom: 5,
  },
  newButton: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderColor: '#000',
    float: 'right',
  }),
  newButtonIcon: helpers.merge(
    {
      marginRight: 10,
    },
    icons.plus
  ),
  menuItem: {
    zIndex: 1000000,
  },
  clearFilters: {
    float: 'left',
    fontSize: 8,
    color: 'black',
    textDecoration: 'underline',
    textAlign: 'center',
    marginRight: '20px',
    marginTop: '15px',
    cursor: 'pointer',
  },
});

const withState = connect(state => {
  const { people, peopleCount, peopleFilters, createdId } = state.dashboard;
  return {
    personDict: people && people.length !== 0 ? people.toDict('_id') : {},
    people: selectPeopleWithPrimaryGroup(people),
    peopleCount,
    peopleFilters,
    createdId,
  };
});

export default withRouter(withState(withStyles(People)));
