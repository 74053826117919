import React, { Component } from 'react';
import classnames from 'classnames';
import { styled, helpers } from 'react-free-style';

import { colors, elements } from '@united-talent-agency/julius-frontend-components';

import { openPersonProfile } from '../../../../../support/person';
import { toDateString } from '../../../../../support/date';
import { projectsUrl } from '../../../../../support/urls';

class NewPersonRow extends Component {
  render() {
    const { styles, person } = this.props;
    const { approval, createdAt, createdBy, createdFor } = person;
    const isNew = approval.status === 'New';
    return (
      <tr>
        <td className={isNew ? styles.tdNew : styles.td}>{approval.status}</td>
        <td className={styles.td}>{createdFor && this.getSourceLink(createdFor, styles)}</td>
        <td className={styles.td}>
          <div className={styles.profileLink} onClick={() => openPersonProfile(person._id)}>
            {person.name} <i className="ml-1 fa fa-external-link align-middle" aria-hidden="true" />
          </div>
        </td>

        <td>
          {createdBy && createdBy.personId && (
            <div className={styles.profileLink} onClick={() => openPersonProfile(createdBy.personId._id)}>
              {createdBy.personId.name}
              <i className="ml-1 fa fa-external-link align-middle" aria-hidden="true" />
            </div>
          )}
        </td>
        <td style={{ width: '10%' }}>{createdAt && toDateString(new Date(createdAt))}</td>
        <td style={{ whiteSpace: 'nowrap' }}>
          {this.renderButton('New')}
          {this.renderButton('In Progress')}
          {this.renderButton('Done')}
        </td>
      </tr>
    );
  }
  getSourceLink(createdFor, styles) {
    switch (createdFor.parentEntity) {
      case 'project':
        return (
          <a
            className={styles.profileLink}
            href={`${projectsUrl}/project/${createdFor.parentId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {createdFor.parentEntity} {createdFor.entity}
            <i className="ml-1 fa fa-external-link align-middle" aria-hidden="true" />
          </a>
        );
      default:
        return (
          <span>
            {createdFor.parentEntity} {createdFor.entity}`
          </span>
        );
    }
  }
  renderButton(status) {
    const { styles, onStatusChanged, person } = this.props;
    return (
      <button
        className={classnames(styles.button, styles.defaultButton)}
        onClick={() => {
          onStatusChanged && onStatusChanged(person._id, status);
        }}
      >
        {status}
      </button>
    );
  }
}

const withStyles = styled({
  button: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    margin: 5,
  }),
  defaultButton: {
    borderColor: colors.darkText,
    width: 86,
    height: 26,
    paddingBottom: 18,
  },
  td: {
    fontSize: 12,
    color: colors.text,
    verticalAlign: 'middle',
  },
  tdNew: {
    color: '#28A745',
    fontWeight: 400,
  },
  profileLink: {
    cursor: 'pointer',
    color: colors.text,
    '&:hover': {
      color: colors.text,
      textDecoration: 'none',
    },
  },
});

export default withStyles(NewPersonRow);
