import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { helpers, styled } from 'react-free-style';
import { debounce } from 'lodash';
import ReactPaginate from 'react-paginate';
import { getGroups, deleteGroup } from '../../../../../api/groups';

import { Table, ButtonColumn, Input, elements, icons } from '@united-talent-agency/julius-frontend-components';
import { listVenues } from '@united-talent-agency/julius-frontend-store';
import { ConfirmModal } from '@united-talent-agency/components';

const BASE_PROFILE_URL = process.env.REACT_APP_PROFILE_URL;

const TABLE_COLUMNS = [
  {
    key: 'name',
    name: 'Name',
    sortable: false,
    width: 200,
  },
  {
    key: '_id',
    name: '',
    width: 100,
    sortable: false,
  },
];

class Companies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      companies: [],
      page: 0,
      itemsPerPage: 50, // 100 for venues
      companiesTotal: null,
      showDeleteConfirmForId: null,
    };
    this.companySearchDebounced = debounce(this.companySearch, 400);
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  componentDidMount() {
    this.refreshCompanies();
  }

  companySearch(searchText) {
    this.setState({ searchText, page: 0 }, this.refreshCompanies);
  }

  async refreshCompanies() {
    const { category } = this.props;
    const { searchText, page, itemsPerPage } = this.state;

    let skip = Math.ceil(page * itemsPerPage);

    if (category === 'Venue') {
      const query = { skip, limit: itemsPerPage, searchText };
      const venuesResponse = await listVenues(query);

      const companies = venuesResponse.body;
      const companiesTotal = venuesResponse.pagination.total;

      this.setState({ companies, companiesTotal });
    } else {
      const response = await getGroups(searchText, skip, itemsPerPage, 'name');

      const companies = (response.data && Object.values(response.data)) || [];
      const companiesTotal = response.meta.total;

      this.setState({ companies, companiesTotal });
    }
  }

  buildProfiles (roles) {
    let profiles = [];
    if (roles) {
      if (roles.projects) {
        const projectRoles = roles.projects;
        if (projectRoles.network) {
          profiles.push('Network');
        }
        if (projectRoles.productionCompany) {
          profiles.push('Production Company');
        }
        if (projectRoles.talentAgency) {
          profiles.push('Talent Agency');
        }
        if (projectRoles.studio) {
          profiles.push('Studio');
        }
        if (projectRoles.promoter) {
          profiles.push('Promoter');
        }
        if (projectRoles.parentCompany) {
          profiles.push('Parent Company');
        }
      }
    }
    return profiles;
  }

  handlePageClick (data) {
    this.setState({ page: data.selected }, this.refreshCompanies);
  }

  render() {
    let { styles, rowSelection, selectRow, category } = this.props;
    let { searchText, companies, itemsPerPage, companiesTotal, page, showDeleteConfirmForId } = this.state;
    if (!companies || !companies[0]) {
      companies = [];
    }

    const isVenuePage = category === 'Venue';

    const companiesLinkedToProfiles = companies.map(company => {
      // RECURSIVE COOL BUG
      if (typeof company.name === 'string') {
        const route = category === 'Venue' ? 'venue' : 'company';
        company.name = (
          <a
            style={{ color: 'black' }}
            target="_blank"
            rel="noopener noreferrer"
            href={`${BASE_PROFILE_URL}/${route}/${company._id}`}
          >
            {company.name}
          </a>
        );
      }
      return company;
    });

    TABLE_COLUMNS[TABLE_COLUMNS.length - 1].formatter = (
      <ButtonColumn buttonText="Delete" onClick={id => this.setState({ showDeleteConfirmForId: id })} />
    );

    return (
      <div>
        <ConfirmModal
          message="Are you sure you want to permanently delete this item?"
          title="Delete Confirmation"
          isOpen={!!showDeleteConfirmForId}
          onConfirm={async () => {
            await deleteGroup(showDeleteConfirmForId);
            this.refreshCompanies();
            this.setState({ showDeleteConfirmForId: null });
          }}
          onCancel={() => this.setState({ showDeleteConfirmForId: null })}
        />
        <h1>Manage - {isVenuePage ? 'Venues' : 'Companies'}</h1>
        <div className={styles.filterBar}>
          <span className={styles.filterLabel}>Filters:</span>
          <Input
            title="Name"
            className={styles.filterField}
            value={searchText}
            onChange={text => this.companySearchDebounced(text)}
          />
          <span className={styles.clearFilters} onClick={() => this.companySearch('')}>
            Clear Filters
          </span>
          <a
            href={`${BASE_PROFILE_URL}/${isVenuePage ? 'venue' : 'company'}`}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.newButton}
          >
            <i className={styles.newButtonIcon} />
            {isVenuePage ? 'Create Venue' : 'Create Company'}
          </a>
        </div>

        <div className={styles.total}>
          {companiesTotal !== null && `${companiesTotal.toLocaleString()} Results`}&nbsp;
        </div>

        <div className={styles.table}>
          <Table
            rowSelection={rowSelection}
            selectable={selectRow}
            columns={TABLE_COLUMNS}
            rows={companiesLinkedToProfiles || []}
          />
          <ReactPaginate
            previousLabel="previous"
            nextLabel="next"
            breakLabel="..."
            breakClassName={styles.breakPagination}
            pageCount={Math.ceil(companiesTotal / itemsPerPage)}
            forcePage={page}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            pageLinkClassName={styles.paginateLinks}
            nextClassName={styles.paginateLinks}
            previousClassName={styles.paginateLinks}
            onPageChange={this.handlePageClick}
            containerClassName={styles.pagination}
            subContainerClassName={styles.pages}
            activeClassName={styles.activePagination}
          />
        </div>
      </div>
    );
  }
}

const TABLE_WIDTH = '1000px';

const withStyles = styled({
  content: {
    fontSize: '12px',
    fontWeight: '100',
  },
  filterBar: {
    width: TABLE_WIDTH,
    height: '70px',
  },
  total: {
    width: TABLE_WIDTH,
    fontSize: '13px',
    fontWeight: 'bold',
    marginLeft: '5px',
    marginBottom: '2px',
  },
  filterLabel: {
    float: 'left',
    fontSize: 16,
    fontWight: 1000,
    textAlign: 'center',
    marginRight: '20px',
    marginTop: '10px',
  },
  pagination: {
    display: 'inline-block',
    float: 'right',
    paddingLeft: 0,
    marginLeft: 0,
    color: 'black',
    '& > li > a': {
      color: 'black !important',
    },
    '& > li': {
      display: 'inline-block',
      color: 'black !important',
      margin: 5,
      padding: 5,
    },
    '& > li.selected': {
      background: 'black',
    },
    '& > li.selected > a': {
      color: 'white !important',
    },
  },
  table: {
    width: TABLE_WIDTH,
  },
  filterField: {
    width: '150px',
    marginRight: 10,
    float: 'left',
  },
  header: {
    color: '#000',
    textAlign: 'center',
    fontSize: '13px',
    lineHeight: '1',
    background: 'white',
    marginBottom: 5,
  },
  newButton: helpers.merge(elements.button, elements.actionable, {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderColor: '#000',
    float: 'right',
    fontSize: '10px',
    '&:hover': {
      textDecoration: 'none',
      color: 'black',
    },
  }),
  newButtonIcon: helpers.merge(
    {
      marginRight: 10,
    },

    icons.plus
  ),
  menuItem: { zIndex: 1000000 },
  paginateLinks: { cursor: 'pointer' },
  clearFilters: {
    float: 'left',
    fontSize: 8,
    color: 'black',
    textDecoration: 'underline',
    textAlign: 'center',
    marginRight: '20px',
    marginTop: '15px',
    cursor: 'pointer',
  },
});

export default withRouter(withStyles(Companies));
